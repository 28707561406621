import React from "react"
import { Link } from "gatsby-plugin-intl"

export default function Footer({ intl }) {
  return (
    <React.Fragment>
      <div className="bg-green-800 py-5 text-center text-white">
        <div className="container mx-auto px-5">
          <h5 className="font-light text-2xl">
            Alastair Fraser Forestry Foundation
          </h5>
          <nav className="py-5">
            <ul className="flex flex-col lg:flex-row mx-auto justify-center align-center font-light">
              <li className="mx-2">
                <Link to="/about-us">
                  {intl.formatMessage({ id: "menu.about" })}
                </Link>
              </li>
              <li className="mx-2">
                <Link to="/activities">
                  {intl.formatMessage({ id: "menu.activities" })}
                </Link>
              </li>
              <li className="mx-2">
                <Link to="/expertise">
                  {intl.formatMessage({ id: "menu.expertise" })}
                </Link>
              </li>
              <li className="mx-2">
                <Link to="/professionals">
                  {intl.formatMessage({ id: "menu.professionals" })}
                </Link>
              </li>
              <li className="mx-2">
                <Link to="/contact">
                  {intl.formatMessage({ id: "menu.contact" })}
                </Link>
              </li>
            </ul>
          </nav>
          <footer className="container mx-auto text-sm text-gray-400">
            <p className="font-normal text-center">
              ⓒ {new Date().getFullYear().toString()} Alastair Fraser Forestry
              Foundation - Registered Charity Number
            </p>
            <p className="font-normal text-center">
              Website made by{" "}
              <a
                href="https://sawirstudio.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                &lt;SawirStudio/&gt;
              </a>
            </p>
          </footer>
        </div>
      </div>
    </React.Fragment>
  )
}
