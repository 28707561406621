import React from "react"
import Img from "gatsby-image"

export default function HeroImage({ image, title, caption }) {
  return (
    <React.Fragment>
      <section className="relative w-full hero-image">
        <Img
          className="inset-0 w-full h-full"
          style={{ position: "absolute" }}
          fluid={image.childImageSharp.fluid}
        />
        <div className="inset-0 absolute flex flex-col justify-center items-center text-white overlay px-5">
          <h1 className="text-6xl font-thin text-center">{title}</h1>
          <p className="text-xl font-light text-center">{caption}</p>
        </div>
      </section>
    </React.Fragment>
  )
}
