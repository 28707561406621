import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby-plugin-intl"

const GoTo = ({ page, image, title, caption, button }) => {
  return (
    <React.Fragment>
      <Link to={page}>
        <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
          <Img
            className="h-full w-full inset-0"
            style={{ position: "absolute" }}
            fluid={image.childImageSharp.fluid}
          />
          <div className="absolute w-full inset-0 flex flex-col justify-center items-center text-white overlay px-4">
            <h3 className="text-3xl lg:text-5xl font-light text-center">
              {title}
            </h3>
            <p className="mb-4 text-center">{caption}</p>

            <button className="bg-none border-white border px-6 py-2 hover:bg-white hover:text-gray-700">
              {button}
            </button>
          </div>
        </div>
      </Link>
    </React.Fragment>
  )
}

export default GoTo
