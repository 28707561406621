import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { changeLocale, Link } from "gatsby-plugin-intl"

export default function Navbar({ intl }) {
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "alastair-logo.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <header className="lg:px-16 px-6 bg-white flex flex-wrap items-center lg:py-2 py-2">
        <div className="flex-1 flex justify-between items-center">
          <Link to="/">
            <Img
              fixed={logo.childImageSharp.fixed}
              alt="Alastair Fraser Forestry Foundation Logo"
            />
          </Link>
        </div>

        <label htmlFor="menu-toggle" className="pointer-cursor xl:hidden block">
          <svg
            className="fill-current text-gray-900"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <title>menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </label>
        <input className="hidden" type="checkbox" id="menu-toggle" />

        <div
          className="hidden xl:flex xl:items-center xl:w-auto w-full"
          id="menu"
        >
          <nav>
            <ul className="lg:flex items-center justify-between text-base text-gray-700 pt-4 lg:pt-0">
              <li>
                <Link
                  className="lg:p-4 py-3 px-0 block border-b-2 text-gray-600 hover:text-green-600 border-transparent"
                  to="/about-us"
                >
                  {intl.formatMessage({ id: "menu.about" })}
                </Link>
              </li>
              <li>
                <Link
                  className="lg:p-4 py-3 px-0 block border-b-2 text-gray-600 hover:text-green-600 border-transparent"
                  to="/activities"
                >
                  {intl.formatMessage({ id: "menu.activities" })}
                </Link>
              </li>

              <li>
                <Link
                  className="lg:p-4 py-3 px-0 block border-b-2 text-gray-600 hover:text-green-600 border-transparent"
                  to="/expertise"
                >
                  {intl.formatMessage({ id: "menu.expertise" })}
                </Link>
              </li>
              <li>
                <Link
                  className="lg:p-4 py-3 px-0 block border-b-2 text-gray-600 hover:text-green-600 border-transparent"
                  to="/professionals"
                >
                  {intl.formatMessage({ id: "menu.professionals" })}
                </Link>
              </li>
              <li>
                <Link
                  className="lg:p-4 py-3 px-0 block border-b-2 text-gray-600 hover:text-green-600 border-transparent"
                  to="/contact"
                >
                  {intl.formatMessage({ id: "menu.contact" })}
                </Link>
              </li>
            </ul>
          </nav>

          <button className="lg:ml-4 flex items-center justify-start lg:mb-0 mb-4 pointer-cursor">
            {intl.locale === "id" ? (
              <img
                className="rounded-full w-10 h-10 border-2 border-gray-300 hover:border-green-400"
                src="https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg"
                alt="Bahasa Indonesia"
                onClick={() => changeLocale("en")}
              />
            ) : (
              <img
                className="rounded-full w-10 h-10 border-2 border-gray-300 hover:border-green-400"
                src="https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
                alt="English"
                onClick={() => changeLocale("id")}
              />
            )}
          </button>
        </div>
      </header>
    </React.Fragment>
  )
}
