import React from "react"
import Navbar from "./navbar"
import Footer from "./footer"

export default function Layout({ children, intl }) {
  return (
    <React.Fragment>
      <div className="flex flex-col min-h-screen font-brand">
        <Navbar intl={intl} />
        {children}
        <div className="flex-1" />
        <Footer intl={intl} />
      </div>
    </React.Fragment>
  )
}
